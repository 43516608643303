import { createRouter, createWebHistory } from 'vue-router'
import handleAlerts from '@/utilities/handleMessages'
import { useAuthStore } from '@/stores/authStore'
import { useTaskStore } from '@stores/taskStore'
import { useCommunicationStore } from '@/stores/communicationStore'

// authentication
import { useAuthorisation } from '@/composables/authorisation'
import ThePermissions from '@/stores/permissions'
import { type PermissionFlag, type PermissionType } from '@/api/auth'
import { initialLoad, initialRoute } from '@/utilities/directRouteManager'

const authorisation = useAuthorisation()
const { getPermissionTypes, getPermissionFlags } = ThePermissions

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import('@views/LoginView.vue'),
        },
        {
            path: '/login/public/:uuid',
            name: 'public',
            component: () => import('@views/PublicLoginView.vue'),
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@views/HomeView.vue'),
        },
        {
            path: '/strategy',
            name: 'strategy',
            component: () => import('@views/StrategyView.vue'),
        },
        {
            path: '/strategy/:name',
            name: 'strategyName',
            component: () => import('@views/StrategyView.vue'),
            props: true,
        },
        {
            path: '/strategy/:name/:id',
            name: 'strategyNameWithId',
            component: () => import('@views/StrategyView.vue'),
            props: true,
        },
        {
            path: '/nolt',
            name: 'nolt',
            component: () => import('@views/NoltView.vue'),
        },
        {
            path: '/i18n',
            name: 'i18n',
            component: () => import('@views/I18nView.vue'),
        },
        {
            path: '/communications',
            name: 'communications',
            component: () => import('@layouts/CommunicationLayout.vue'),
            beforeEnter: (to, _, next) => {
                if (to.name === 'communications') {
                    next({ path: 'communications/calendar/month' })
                } else {
                    next()
                }
            },
            children: [
                {
                    path: 'calendar/:period',
                    name: 'calendar',
                    component: () => import('@views/communication/CommunicationCalendar.vue'),
                    props: true,
                },
                {
                    path: 'calendar/:period/:id',
                    name: 'communicationDetails',
                    component: () => import('@views/communication/CommunicationCalendar.vue'),
                    props: true,
                },
                {
                    path: 'list',
                    name: 'list',
                    component: () => import('@views/communication/CommunicationList.vue'),
                },
                {
                    path: 'list/:id',
                    name: 'listCommunicationDetails',
                    component: () => import('@views/communication/CommunicationList.vue'),
                    props: true,
                },
                {
                    path: 'kanban',
                    name: 'kanban',
                    component: () => import('@views/communication/CommunicationKanban.vue'),
                    meta: {
                        permissionFlag: 'canUseKanban',
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'kanban/:id',
                    name: 'kanbanCommunicationDetails',
                    component: () => import('@views/communication/CommunicationKanban.vue'),
                    props: true,
                },
                {
                    path: 'timeline',
                    name: 'timeline',
                    component: () => import('@views/communication/CommunicationTimeline.vue'),
                },
                {
                    path: 'timeline/:id',
                    name: 'timelineCommunicationDetails',
                    component: () => import('@views/communication/CommunicationTimeline.vue'),
                    props: true,
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@views/communication/CommunicationDashboard.vue'),
                },
            ],
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: () => import('@layouts/TaskLayout.vue'),
            children: [
                {
                    path: 'calendar/:period',
                    name: 'tasksCalendar',
                    component: () => import('@views/task/TaskCalendar.vue'),
                    props: true,
                    meta: {
                        permissionType: 'task',
                        showIfOnlyRead: false,
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'calendar/:period/:type/:id',
                    name: 'taskCalendarDetails',
                    component: () => import('@views/task/TaskCalendar.vue'),
                    props: true,
                },
                {
                    path: 'list',
                    name: 'tasksList',
                    component: () => import('@views/task/TaskList.vue'),
                    meta: {
                        permissionType: 'task',
                        showIfOnlyRead: false,
                        fallback: 'calendar',
                    },
                },
                {
                    path: 'list/:type/:id',
                    name: 'taskDetailsInComm',
                    component: () => import('@views/task/TaskList.vue'),
                    props: true,
                },
            ],
        },
        {
            path: '/calendars',
            name: 'calendars',
            meta: {
                permissionType: 'calendar',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
            component: () => import('@views/CalendarsView.vue'),
        },
        {
            path: '/calendars/:id',
            name: 'calendarDetails',
            component: () => import('@views/CalendarsView.vue'),
            props: true,
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@views/UsersView.vue'),
            meta: {
                permissionType: 'webuser',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/users/:id',
            name: 'userDetails',
            component: () => import('@views/UsersView.vue'),
            props: true,
        },
        {
            path: '/users/activate/:key',
            name: 'userActivate',
            component: () => import('@views/UsersActivateView.vue'),
        },
        {
            path: '/partner',
            name: 'partners',
            component: () => import('@views/PartnerView.vue'),
            meta: {
                permissionType: 'collaboration',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/workflows',
            name: 'workflows',
            component: () => import('@views/WorkflowsView.vue'),
            meta: {
                permissionType: 'workflow',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/workflows/:id',
            name: 'workflowDetails',
            component: () => import('@views/WorkflowsView.vue'),
            props: true,
        },
        {
            path: '/holidays',
            name: 'holidays',
            component: () => import('@views/HolidaysView.vue'),
            meta: {
                permissionType: 'holiday',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
        },
        {
            path: '/holidays/:id',
            name: 'holidayDetails',
            component: () => import('@views/HolidaysView.vue'),
            props: true,
        },
        {
            path: '/request',
            name: 'request',
            component: () => import('@views/requestView.vue'),
            meta: {
                permissionType: 'request',
                showIfOnlyRead: true,
                fallback: 'calendar',
            },
        },
        {
            path: '/request/:id',
            name: 'requestDetails',
            component: () => import('@views/requestView.vue'),
            props: true,
        },
        {
            path: '/organization',
            name: 'organization',
            component: () => import('@components/account/AccountLayout.vue'),
            meta: {
                permissionType: 'organisation',
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
            children: [
                {
                    path: '/organization/account',
                    name: 'account',
                    component: () => import('@views/organization/AccountView.vue'),
                },
                {
                    path: '/organization/preferences',
                    name: 'preferences',
                    component: () => import('@views/organization/AccountThemes.vue'),
                },
                {
                    path: '/organization/subscription',
                    name: 'subscription',
                    component: () => import('@views/organization/AccountSubscription.vue'),
                },
                {
                    path: '/organization/notes',
                    name: 'notes',
                    component: () => import('@views/organization/AccountNotes.vue'),
                },
            ],
        },
        {
            path: '/user',
            name: 'user',
            component: () => import('@layouts/UserProfileLayout.vue'),
            meta: {
                showIfOnlyRead: false,
                fallback: 'calendar',
            },
            children: [
                {
                    path: '/user/profile',
                    name: 'profile',
                    component: () => import('@views/user/ProfileView.vue'),
                },
                {
                    path: '/user/security',
                    name: 'security',
                    component: () => import('@views/user/SecurityView.vue'),
                },
                {
                    path: '/user/notifications',
                    name: 'notifications',
                    component: () => import('@views/user/notificationsView.vue'),
                },
                {
                    path: '/user/twofactor',
                    name: 'twofactor',
                    component: () => import('@views/user/TwofactorView.vue'),
                },
            ],
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('@views/SignupView.vue'),
        },
        {
            path: '/signup/activate/:key',
            name: 'signupActivate',
            component: () => import('@views/SignupActivateView.vue'),
        },
        {
            path: '/recurringcommunication',
            name: 'recurringcommunication',
            component: () => import('@views/CommunicationRecurringView.vue'),
        },
        {
            path: '/requestpassword',
            name: 'requestpassword',
            component: () => import('@views/RequestPasswordView.vue'),
        },
        {
            path: '/password-forgot/:key',
            name: 'resetpassword',
            component: () => import('@views/ResetPasswordView.vue'),
        },
        {
            path: '/test',
            name: 'test',
            component: () => import('@views/TestView.vue'),
        },
        {
            path: '/release',
            name: 'releasenotes',
            component: () => import('@views/ReleasenotesView.vue'),
        },
        {
            path: '/authorisationtest',
            name: 'authtest',
            component: () => import('@views/AuthorisationTestView.vue'),
        },
        {
            path: '/dev',
            name: 'dev',
            component: () => import('@views/DevView.vue'),
        },
        {
            path: '/sso/:uuid',
            name: 'ssoreturn',
            component: () => import('@views/SsoReturnView.vue'),
        },
        {
            path: '/generalerror',
            name: 'generalerror',
            component: () => import('@views/GeneralErrorView.vue'),
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: () => import('@views/NotFound.vue'),
        },
    ],
})

async function permissionGuard(meta: { permissionType: PermissionType; showIfOnlyRead: boolean }) {
    if ((await authorisation.isReadOnly.value(meta.permissionType)) && !meta.showIfOnlyRead) {
        return false
    }
    return true
}

async function flagGuard(meta: { permissionFlag: PermissionFlag }) {
    await getPermissionFlags()
    if (meta.permissionFlag) {
        return authorisation.hasFlag.value(meta.permissionFlag)
    }
    return true
}

router.beforeEach(async (to, from, next) => {
    console.log('from route: ', from)

    // Set communication detail
    if (to.name === 'communicationdetail') {
        const commStore = useCommunicationStore()
        commStore.communicationId = Number(to.params.id) || 0
    }
    // check if to.path contains 'communications'
    if (to.path.includes('communications')) {
        const commStore = useCommunicationStore()
        const options = commStore.viewTypeOptions
        if (to.name && options.includes(String(to.name) as any)) {
            commStore.viewType = to.name as any
        } else if (!to.name) {
            commStore.viewType = 'calendar' as any
        }
    }

    const store = useAuthStore()
    const bypass = [
        'login',
        'register',
        'resetpassword',
        'signup',
        'signupActivate',
        'requestpassword',
        'password-forgot',
        'reset-password',
        'public',
        'userActivate',
        'ssoreturn',
        'generalerror',
        '',
        null,
    ]

    const name = to.name as string

    if (initialLoad.value && !initialRoute.value) {
        initialRoute.value = { name, params: to.params, query: to.query }
    }

    if (bypass.includes(name)) {
        next()
        return
    }

    if (!store.isLoggedIn) {
        handleAlerts.setInfo('Log in om verder te gaan')
        next({ name: 'login' })
        return
    }

    // CUSTOM check Requests read
    if (to.path.includes('request')) {
        await getPermissionTypes()
        if (!authorisation.canRead.value('request')) {
            next({ name: 'calendar' })
            return
        }
    }

    if (to.path.includes('tasks')) {
        const taskStore = useTaskStore()
        const options = taskStore.viewTypeOptions
        if (to.name && options.includes(String(to.name) as any)) {
            taskStore.viewType = to.name as any
        } else if (!to.name) {
            taskStore.viewType = 'task' as any
        }
    }

    // Routerguard: Check if permissionFlag is available, else fallback.
    if (Object.keys(to.meta).length && to.meta.permissionFlag && !(await flagGuard(to.meta as any))) {
        next({ name: (to.meta?.fallback as string) || 'calendar' })
        return
    }

    // Routerguard: Check if CRUD = create && is readonly, else fallback.
    if (Object.keys(to.meta).length && to.meta.permissionType && !(await permissionGuard(to.meta as any))) {
        next({ name: (to.meta?.fallback as string) || 'calendar' })
        return
    }

    next()
})

export default router
